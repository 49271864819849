<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div>
      <div class="mb-6">
        <h2 class="font-semibold text-blue-800 text-2xl">
          Rules Manager
        </h2>
      </div>
      <div class="rounded bg-blue-50 p-10">
        <p class="text-xl">
          Fraudulent Account Rules Manager
        </p>
        <p>
          Set rules to manage fraudulent accounts, enter and manage
          <span class="text-blue-800">Keywords, Set Transaction Value</span> and
          <span class="text-blue-800">Sign up Time</span> to automatically flag
          <br />
          <button class="bg-blue-900 mt-5 text-white py-3 px-5 rounded">
            Accounts
          </button>
        </p>
      </div>
      <div class="flex my-10 p-10">
        <div class="w-1/2">
          <h4 class="text-xl">Keyword Manager</h4>
          <p>Choose Account type and enter keywords to set rule</p>
          <button class="bg-blue-900 text-white py-3 px-5 rounded">
            Merchant Account Name
          </button>
          <button
            class="border-2 m-5 text-blue border-blue-900 py-2 px-5 rounded"
          >
            Fixed Account Name
          </button>
        </div>
        <div class="w-1/2">
          <h4 class="text-xl">Business Name Manager</h4>
          <p>Set minimum character for business name</p>
          <input
            type="text"
            class="inline-block  px-2 ring-2 outline-none ring-blue-900 rounded-sm ml-2 w-64 text-sm py-3"
            name=""
            placeholder="Enter Minimum character"
            id=""
            v-model="search"
          />
          <button
            class="border-2 m-5 text-blue border-blue-900 py-2 px-5 rounded"
          >
            Enter Name
          </button>
        </div>
      </div>
      <div class="flex my-10 p-10 border-t-2 rounded">
        <div class="w-1/2 p-5">
          <h4 class="text-xl text-blue-900">IP Address Manager</h4>
          <p>Enter IP Address and Manage blacklisted IP Address</p>
          <div class="mt-5 content-center">
            <p class="text-blue-900">Blacklisted IP Address</p>

            <input
              type="text"
              class="inline-block  px-2 ring-2 outline-none ring-blue-900 rounded-sm ml-2 w-64 text-sm py-3"
              name=""
              placeholder="Enter IP Address"
              id=""
              v-model="search"
            />
            <button class="m-5 text-white bg-blue-900 py-3 px-5 rounded">
              Add IP Address
            </button>
          </div>
        </div>
        <div class="w-1/2">
          <h4 class="text-xl text-blue-900">Transaction Time Manager</h4>
          <p>Set Signup time, Transaction Value and transaction time range</p>
          <div class="mt-5">Transaction Value</div>
          <input
            type="text"
            class="block px-2 ring-2 outline-none ring-blue-900 rounded-sm ml-2 w-100 text-sm py-3"
            name=""
            placeholder="Enter Minimum character"
            id=""
            v-model="search"
          />
          <p class="mt-4">Sign up Time</p>
          <input
            type="time"
            class="inline-block border-2 p-2 border-blue-900 rounded-sm mr-2 w-52"
            name=""
            id=""
            v-model="dateData.endTime"
          />
          <label class="p-3">To</label>
          <input
            type="time"
            class="inline-block border-2 p-2 border-blue-900 rounded-sm mr-2 w-52"
            name=""
            id=""
            v-model="dateData.endTime"
          />
          <select
            class="inline-block border-1 p-3 border-blue-900 rounded-sm mr-2 w-52"
          >
            <option value="5">5 Mins</option>
            <option value="10">10 Mins</option>
            <option value="15">15 Mins</option>
            <option value="20">20 Mins</option>
            <option value="25">25 Mins</option>
            <option value="30">30 Mins</option>
            <option value="35">35 Mins</option>
            <option value="40">40 Mins</option>
            <option value="45">45 Mins</option>
            <option value="50">50 Mins</option>
            <option value="55">55 Mins</option>
          </select>

          <button
            class="block mt-5 text-white bg-blue-900 py-3 px-5 rounded self-center"
          >
            Set Rule
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      dateData: null,
      tim: new Date().getTime(),
      filterBy: [],
      search: null,
      walletFilter: [],
      downloadLink: null,
      loading: false,
      viewModal: false,
      payload: [],
      fundsData: [],
    };
  },
  created() {
    const d = new Date();

    this.dateData = {
      start_date: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };

    this.payload = {
      start_date: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      end_date: new Date().toJSON().split("T")[0],
    };

    this.fetchRecords();
  },
  computed: {},

  methods: {
    downloadCSV() {
      window.open(this.downloadLink, "_blank");
    },
    async fetchRecords() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "FETCH_FUNDS_TRANSFER",
          this.dateData
        );
        if (res.status == true) {
          this.fundsData = res.data;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}

      this.isLoading = false;

      //const res = this.$store.getters["GET_PAYMENT_LOGS_RES_CSV"];
    },
  },
};
</script>
<style>
.text-blue {
  color: #253b95;
}
.bg-blue {
  background-color: #253b95;
}
</style>
